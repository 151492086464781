// Input header
export const useScrollStore = defineStore('scroll', () => {
  const isScrolledInput = ref(false)
  const isHalfScrolled = ref(false)

  function checkScrollInput() {
    const height = window.innerHeight - (window.innerHeight / 2)
    isScrolledInput.value = window.scrollY > (height + 150)

    const halfHeight = window.innerHeight - (window.innerHeight / 1.25)
    isHalfScrolled.value = window.scrollY > halfHeight
  }

  return { isScrolledInput, checkScrollInput, isHalfScrolled }
})